import AOS from 'aos';
import 'aos/dist/aos.css';

const FIRST_VISIT_DELAY = 30000; // 30 seconds
const QR_VISIT_DELAY = 100; // 0.1 seconds

const isTourPage = document.body.getAttribute('data-is-tour-page') === 'true';
const isKidsPage = document.body.getAttribute('data-is-kids-page') === 'true';
const isCashPage = document.body.getAttribute('data-is-cash-page') === 'true';
const isCashKidsPage = document.body.getAttribute('data-is-cash-kids-page') === 'true';
const isSchoolPage = document.body.getAttribute('data-is-school-page') === 'true';

import * as Bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as TitleNotifications from './modules/titleNotifications';
import * as VisitorTracking from './modules/visitorTracking';
import * as DynamicContentLoader from './modules/dynamicContentLoader';
import { getIntlTelInputLoadedStatus, setIntlTelInputLoadedStatus } from './modules/dynamicContentLoader';
import * as Utils from './modules/utils';
import * as TourDisplay from './modules/tourDisplay';
import * as JobDisplay from './modules/jobsDisplay';
import * as ZoniPhoneNumbers from './modules/zoniPhoneNumbers';
import * as Scroll from './modules/scroll';
import * as ResizeListenUpdates from './modules/resizeListenUpdates';
import * as GoogleTracking from './modules/googleTracking';
import * as EnrollNow from './modules/account/enrollNow';
import * as PasswordValidator from './modules/account/passwordValidator';
import * as activationCodeValidator from './modules/account/activationCodeValidator';
import * as Translator from './modules/translateThroughClick';
import { translateText } from './modules/translator';
import { Application } from '@splinetool/runtime';
import * as StoreProducts from './modules/storeProducts';

window.bootstrap = Bootstrap;
window.frappe = frappe;

const initializeCanvas = (canvasId, sceneUrl) => {
    const canvasElement = document.getElementById(canvasId);
    if (canvasElement) {
        const canvas = new Application(canvasElement, { renderMode: 'continuous' });
        canvas.load(sceneUrl);
        canvas.setSize(1440, 900);
    }
};

initializeCanvas('canvas3dBanner', 'https://prod.spline.design/bj5cUnJdUrl54D4B/scene.splinecode');
initializeCanvas('canvas3dBannerMobile', 'https://prod.spline.design/H16evIkV9o5mUg1J/scene.splinecode');
initializeCanvas('canvas3dNJ', 'https://prod.spline.design/izH-8jL1u0RvJS2k/scene.splinecode');
initializeCanvas('canvas3dNY', 'https://prod.spline.design/impo8dWkmQNuCH4W/scene.splinecode');
initializeCanvas('canvas3dFL', 'https://prod.spline.design/EomKOCJX0di9vXbp/scene.splinecode');


$('#showConfirmPassword').on('click', function(e){
    e.preventDefault();
    if($('#icon2').hasClass('fa-eye-slash')){
        $('#icon2').removeClass('fa-eye-slash').addClass('fa-eye');
        $('#confirmPassword').attr('type', 'text');
  
    }
    else{
        $('#icon2').removeClass('fa-eye').addClass('fa-eye-slash'); 
        $('#confirmPassword').attr('type', 'password');
    
    }

})
$('#showPassword').on('click', function(e){
    e.preventDefault();
    if($('#icon1').hasClass('fa-eye-slash')){
        $('#icon1').removeClass('fa-eye-slash').addClass('fa-eye');
        $('#password').attr('type', 'text');
       
    }
    else{
        $('#icon1').removeClass('fa-eye').addClass('fa-eye-slash'); 
        $('#password').attr('type', 'password');
      
    }

})

const qr_utm = VisitorTracking.hasMatchingQr();

if (VisitorTracking.hasMatchingQrAndIsScanSource()) {

    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModal() || !modalElement) {
            return;
        }
        
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);
} else if (VisitorTracking.hasMatchingQRisSchoolClosed()) {
    VisitorTracking.customizeModalSchoolCloseWhatsApp();

    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModal() || !modalElement) {
            return;
        }

        VisitorTracking.customizeModalSchoolCloseWhatsApp;
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');
    }, QR_VISIT_DELAY);
} else if (VisitorTracking.hasMatchingQRisOrlandoPromo()) {
    VisitorTracking.customizeModalOrlandoPromoWhatsApp();

    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModal() || !modalElement) {
            return;
        }

        VisitorTracking.customizeModalOrlandoPromoWhatsApp;
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');
    }, QR_VISIT_DELAY);
} else if (VisitorTracking.hasMatchingQRisTampaPromo()) {
    VisitorTracking.customizeModalTampaPromoWhatsApp();

    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModal() || !modalElement) {
            return;
        }

        VisitorTracking.customizeModalTampaPromoWhatsApp;
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');
    }, QR_VISIT_DELAY);
} else if (VisitorTracking.isFirstTimeVisitor() && !isTourPage) {
    VisitorTracking.customizeModalForFirstTimeVisitor();

    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }

        VisitorTracking.customizeModalForFirstTimeVisitor();
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, FIRST_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds
} else if (VisitorTracking.hasMatchingToursQrUTMParameters()) {
    VisitorTracking.customizeModalForToursQrUTM();
    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }

        VisitorTracking.customizeModalForToursQrUTM();
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds}
} else if (VisitorTracking.hasMatchingQrRollupUTMParameters()) {
    VisitorTracking.customizeModalForQrRollupVisitor();
    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }
        
        VisitorTracking.customizeModalForQrRollupVisitor();
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds}
} else if (VisitorTracking.hasMatchingQrKidsFreeTrialUTMParameters()) {
    
    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }
        
        VisitorTracking.setIsKidsFreeTrial(true);
        VisitorTracking.customizeModalForKidsFreeTrial();
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds}
} else if (VisitorTracking.hasMatchingQr() && VisitorTracking.hasMatchingQr().utm_campaign == "kids50off-24-04") {
    VisitorTracking.customizeModalKidsPage();
    VisitorTracking.customizeModalForkids50off();
        
    setTimeout(function () {
        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }       
        
        VisitorTracking.customizeModalKidsPage();
        VisitorTracking.customizeModalForkids50off();
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);
} else if (VisitorTracking.isFirstTimeVisitor() && isTourPage) {
    VisitorTracking.customizeModalToursPage();
    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }
        
        VisitorTracking.customizeModalToursPage();
        var bootstrapModal = new bootstrap.Modal(modalElement);
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds}
} else if (VisitorTracking.isFirstTimeVisitor() && isCashPage) {
    VisitorTracking.customizeModalCashPage();
    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }
        VisitorTracking.customizeModalCashPage();
        var bootstrapModal = new bootstrap.Modal(modalElement);
        new bootstrap.Modal(modalElement).show();
        TitleNotifications.showNotificationInTitle();
        localStorage.setItem('isOldVisitor', 'true');

    }, QR_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds}
} else if (VisitorTracking.isFirstTimeVisitor() && isCashKidsPage) {
    VisitorTracking.customizeModalKidsCashPage();
    setTimeout(function () {

        var modalElement = document.getElementById('zoniModal');
        if (VisitorTracking.checkOpenModalOrOldVisitor() || !modalElement) {
            return;
        }

        VisitorTracking.customizeModalKidsCashPage();
        var bootstrapModal = new bootstrap.Modal(modalElement);
        bootstrapModal.show();

    }, QR_VISIT_DELAY);  // FIRST_VISIT_DELAY milliseconds = 30 seconds}
}


document.getElementById('zoniModal')?.addEventListener('show.bs.modal', function (event) {
    if (!getIntlTelInputLoadedStatus()) {
        DynamicContentLoader.loadAndInitializeIntlTelInput();
        // Load the intlTelInput styles
        setIntlTelInputLoadedStatus(true); // set to true so we don't load it again
    }
    // Check if the modal was triggered by the "Plan your own trip" button
    const triggerElement = event.relatedTarget;
    if (triggerElement && triggerElement.classList.contains('plan-your-trip-btn')) {
        // Call the customizeModalForPlanYourOwnTrip function to adjust the modal content
        VisitorTracking.customizeModalForPlanYourOwnTrip();
    }

    if (triggerElement && triggerElement.classList.contains('order-brochure-btn')) {
        // Call the customizeModalForPlanYourOwnTrip function to adjust the modal content
        VisitorTracking.customizeModalForOrderBrochure();
    }

    if (triggerElement && triggerElement.classList.contains('how-can-we-help-btn')) {
        // Call the customizeModalForPlanYourOwnTrip function to adjust the modal content
        VisitorTracking.customizeModalForPartners();
    }

    if (triggerElement && triggerElement.classList.contains('whatsapp-link')) {
        // Call the customizeModalKidsPageForSignUpToday function to adjust the modal content
        VisitorTracking.customizeModalWhatsApp();
    }
    if (triggerElement && triggerElement.classList.contains('btn-cash-kids')) {
        VisitorTracking.customizeModalKidsCashPage();
    }
    if (triggerElement && triggerElement.classList.contains('btn-header')) {
        VisitorTracking.setModalToDefault();
    }
  
});



$(document).ready(function () {

    var $video = $("#banner_video");

    if ($video.length) { // Check if element exists
        var windowWidth = $(window).width();

        if (windowWidth < 768) {
            $video.attr('src', $video.data('sm'));
        } else {
            $video.attr('src', $video.data('md'));
        }

        // This line ensures that the video plays after changing the source.
        $video.get(0).load();
    }

    var $videoKids = $("#kids_awards_video");

    if ($videoKids.length) { // Check if element exists
        var windowWidth = $(window).width();

        if (windowWidth < 768) {
            $videoKids.attr('src', $videoKids.data('sm'));
        } else {
            $videoKids.attr('src', $videoKids.data('md'));
        }

        // This line ensures that the video plays after changing the source.
        $videoKids.get(0).load();
    }

    // Browser details
    let userLanguage = navigator.language || navigator.userLanguage;
    let userAgent = navigator.userAgent;

    $('#zoni-form').on('submit', async function (e) {
        e.preventDefault();

        if ($('#phone_number').val()) {
            // It's probably a bot, don't submit the form.
            return;
        }

        // Validate phone number using intlTelInput's isValidNumber
        if (!iti.isValidNumber()) {
            await showAlert('Please enter a valid phone number for the selected country.');
            return; // Stop the submission
        }

        // Prepare references outside try/catch
        let normalizedPhone;
        let normalizedEmail;
        let formData = {};

        // Disable the submit button to prevent multiple submissions
        const submitButton = document.getElementById('zoniModalSubmitButton');
        submitButton.disabled = true;
        
        // Show the loading spinner
        const submitSpinner = document.getElementById('zoniModalLoadingSpinner');
        const submitText = submitButton.textContent;
        if (submitSpinner) {
            submitSpinner.classList.remove('d-none');
        }
        submitButton.textContent = ' ... ';
        submitButton.prepend(submitSpinner);  // Keep spinner visible after text change



        try {
            const fullNumber = iti.getNumber();
            normalizedPhone = GoogleTracking.normalizePhoneNumber(fullNumber);
            normalizedEmail = GoogleTracking.normalizeEmail($('#email').val());
            let countryData = iti.getSelectedCountryData();
            let personTypeCheckboxes = VisitorTracking.mapPersonTypeToCheckboxes($('#person_type').val());
            let gclid = new URL(window.location.href).searchParams.get('gclid');  // Extract the gclid parameter from the current URL

        let message = $('#message').val();
        if (isCashPage) {
            message = message + "\n I am claiming my $25 OFF in my registration fee.";
        }
        if (isKidsPage) {
            message = message + "\n I'm interested in Zoni Kids.";
        }
        if (isCashKidsPage) {
            message = message + "\n I was referred for Zoni Kids Private Lessons by " + $('#referrer_name').val() + ".";
        }
        if (qr_utm.utm_campaign == "kids50off-24-04") {
            message = message + "\n I am claiming my 50% OFF in my Zoni Kids registration fee.";
        }

            formData = {
                full_name: $('#full_name').val(),
                email: normalizedEmail,
                message: message,
                student_name: $('#student_name').val(),
                phone: normalizedPhone,
                country_code: countryData.dialCode,
                country_name: countryData.name,
                language: userLanguage,
                user_agent: userAgent,
                referrer_url: document.referrer || 'Direct',  // if referrer is not available, mark it as 'Direct'
                google_click_identifier: gclid || '', // Use the extracted gclid value or an empty string if it's not available
                origin_page: window.location.href,  // Get the full page URL
                visited_before: VisitorTracking.visitedBefore,
                referrer_name: $('#referrer_name').val(),
                referrer_email: $('#referrer_email').val(),
                referrer_birth_date: $('#referrer_birth_date').val(),
                referrer_student_id: $('#referrer_student_id').val(),
                destination: $('#destination').val(),
                departure_city: $('#departure_city').val(),
                school_or_organization: $('#school_or_organization').val(),
                tour: VisitorTracking.isTourInterest,
                zoni_kids: VisitorTracking.isKidsInterest,
                zoni_american_high_school: VisitorTracking.isAmericanHighSchoolInterest,
                zoni_american_high_school_enrollment: VisitorTracking.isAmericanHighSchoolEnrollment,
                kids_free_trial: VisitorTracking.isKidsFreeTrial,
                parent_name: $('#parent_name').val(),
                learn: VisitorTracking.isLearnInterest,
                order_brochure: VisitorTracking.isOrderBrochure,
                is_whatsapp: VisitorTracking.isWhatsAppChat,
                is_school_closed: VisitorTracking.isSchoolClosed,
                is_orlando_promo: VisitorTracking.isOrlandoPromo,
                is_tampa_promo: VisitorTracking.isTampaPromo,
                ...personTypeCheckboxes
            };

            // Prepare Google Tag Manager Enhanced Conversion Data
            window.dataLayer.push({
                'event': 'set_user_data',
                'phone_number': normalizedPhone,
                ...formData
            });

            const response = await $.ajax({
                method: "POST",
                url: "/api/resource/Contacts",
                data: JSON.stringify(formData),
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "X-Frappe-CSRF-Token": frappe.csrf_token
                }
            });

            // Handle success
            handleFormSuccess(response, formData, normalizedPhone, normalizedEmail);

        } catch (err) {
            handleFormError(err, normalizedPhone, formData);
        } finally {
            // Re-enable the submit button and hide the spinner
            if (submitSpinner) {
                submitSpinner.classList.add('d-none');
            }
            submitButton.textContent = submitText;
            submitButton.disabled = false;
        }
    });

    async function handleFormSuccess(data, formData, normalizedPhone, normalizedEmail) {
        // Get the full query string from the main page URL
        const queryString = window.location.search;
        // Load the "Thank You" page into the hidden iframe with the entire query string
        localStorage.setItem('userData', JSON.stringify({
            'email': normalizedEmail,
            'phone_number': normalizedPhone
        }));
        const iframeURL = `/zonidirect/index-typ.html${queryString}`;
        
        VisitorTracking.setEmail(normalizedEmail);
        VisitorTracking.setPhone(normalizedPhone);

        // Set the iframe's src to the "Thank You" page for legacy reasons
        // If you remove the next code line means that the marketing team
        // has updated the Google Ads and Google Analytics procedures
        // and the iframe is not needed anymore
        document.getElementById("thankYouFrame").src = iframeURL;
        
        // Hide all open modals
        var openModals = document.querySelectorAll('.modal.show');
        openModals.forEach(modal => {
            var bootstrapModal = bootstrap.Modal.getInstance(modal);
            bootstrapModal.hide();
        });

        localStorage.setItem('isOldVisitor', 'true'); // Set the isOldVisitor flag in local storage

        // Reset the viewport zoom to default
        $('meta[name="viewport"]').attr('content', 'width=device-width, initial-scale=1, maximum-scale=1');

        $('#zoni-form')[0].reset();     // Reset the form after submission
        TitleNotifications.removeNotificationFromTitle(); // Remove the notification from the title

        await showAlertBasedOnCondition(formData);

        // Push event to GTM
        window.dataLayer.push({
            'event': 'form_success_submission',
            'phone_number': normalizedPhone,
            ...formData
        });
    }

    function handleFormError(err, normalizedPhone, formData) {
        let errorMessage = "There was an error. Please try again!";
        try {
            let parsedError = JSON.parse(err.responseText);
            errorMessage = JSON.parse(parsedError._server_messages)[0].message;
        } catch (e) {
            console.error('Error parsing server response:', e);
        }

        showAlert(errorMessage);

        window.dataLayer.push({
            'event': 'form_error_submission',
            'phone_number': normalizedPhone,
            ...formData
        });
    }


    async function showAlertBasedOnCondition(formData) {
        if (VisitorTracking.isOrderBrochure) {
            await showAlert("Your order brochure request will be processed immediately! Click OK to open the brochure. One of our representatives will contact you soon to offer you printed materials or future assistance. Thank you for your Zoni Tours brochure order!", formData);
        } else if (isCashPage) {
            await showAlert("Thank you for reaching out to Zoni! Your message has been successfully received. One of our team representatives will process your message and will be in touch with you soon. We appreciate your patience and interest! Your $25 OFF in your registration fee has been granted.", formData);
        } else if (isCashKidsPage) {
            await showAlert("Thank you for your referral! Your message has been successfully received. One of our team representatives will be in touch with the referee shortly. We appreciate your patience and interest! You’re on track to receive a $25 discount on your next tuition payment once the referral meets the program requirements.", formData);
        } else if (VisitorTracking.isWhatsAppChat || VisitorTracking.isSchoolClosed  || VisitorTracking.isOrlandoPromo || VisitorTracking.isTampaPromo) {
            await showAlert("Thank you! Click OK, and you will be redirected to a WhatsApp live chat where one of our representatives will chat with you.", formData);
        } else if (VisitorTracking.isAmericanHighSchoolEnrollment) {
            await activationCodeValidator.showCodeValidatorModal(VisitorTracking.email, VisitorTracking.phone);
        } else if (VisitorTracking.isKidsFreeTrial) {
            await showAlert("Thank you! We have received your information. Please click OK to continue with the free trial lesson booking.", formData);
        } else {
            await showAlert("Thank you for reaching out to Zoni! Your message has been successfully received. One of our team representatives will process your message and will be in touch with you soon. We appreciate your patience and interest!", formData);
        }
    }

    async function showAlert(message, formData = null) {
        return new Promise(async (resolve) => {  // Add `async` keyword here
            message = await translateText(message);
            // Show the alert modal
            $('#alertModal').modal('show');
            $('#alertModalContent').html(message); // Set the message in the modal content
    
            // Add an "OK" button to the modal
            const okButton = $('<button>')
                .addClass('btn btn-primary')
                .text('OK')
                .attr('id', 'alertOkButton')
                .on('click', function () {
                    $('#alertModal').modal('hide');
                    resolve(); // Resolve the promise when OK is clicked
                });
    
            $('#alertModalContent').append('<br><br>'); // Add some spacing
            $('#alertModalContent').append(okButton); // Append the OK button
    
            $('#alertModal').on('hidden.bs.modal', function () {
                if (VisitorTracking.isOrderBrochure) {
                    window.location.href = 'https://www.zoni.edu/s3/brochures/Zoni_Tours/index.html';
                } else if (VisitorTracking.isWhatsAppChat && !VisitorTracking.isSchoolClosed && !VisitorTracking.isOrlandoPromo && !VisitorTracking.isTampaPromo) {
                    window.location.href = '/api/method/zoni_edu.controller.redirect_to_wp?message=' + encodeURIComponent(formData.message) + '&phone=' + encodeURIComponent(formData.phone) + '&email=' + encodeURIComponent(formData.email) + '&name=' + encodeURIComponent(formData.full_name);
                } else if (VisitorTracking.isSchoolClosed) {
                    window.location.href = '/api/method/zoni_edu.controller.redirect_to_closed_school_wp?message=' + encodeURIComponent(formData.message) + '&phone=' + encodeURIComponent(formData.phone) + '&email=' + encodeURIComponent(formData.email) + '&name=' + encodeURIComponent(formData.full_name);
                } else if (VisitorTracking.isOrlandoPromo) {
                    window.location.href = '/api/method/zoni_edu.controller.redirect_to_orlando_promo_wp?message=' + encodeURIComponent(formData.message) + '&phone=' + encodeURIComponent(formData.phone) + '&email=' + encodeURIComponent(formData.email) + '&name=' + encodeURIComponent(formData.full_name);
                } else if (VisitorTracking.isTampaPromo) {
                    window.location.href = '/api/method/zoni_edu.controller.redirect_to_tampa_promo_wp?message=' + encodeURIComponent(formData.message) + '&phone=' + encodeURIComponent(formData.phone) + '&email=' + encodeURIComponent(formData.email) + '&name=' + encodeURIComponent(formData.full_name);
                } else if (VisitorTracking.isPartnersPage) {
                    window.location.href = '/school/partners_page';
                } else if (VisitorTracking.isKidsFreeTrial) {
                    redirect_url = "/kids";
    
                    $.ajax({
                        method: 'GET',
                        url: '/api/method/zoni_edu.zoni_edu.integrations.zoni_crm.get_last_crm_lead_uid?email=' + encodeURIComponent(formData.email),
                        dataType: 'json',
                        success: function (data) {
                            if (data.message) {
                                if (window.location.hostname !== "zoni.local") {
                                    redirect_url = 'https://crm.zoni.edu/freeTrial/zkd?crmLeadUid=' + data.message;
                                } else {
                                    redirect_url = 'https://testcrm.zoni.edu/freeTrial/zkd?crmLeadUid=' + data.message;
                                }
                            }
                            // Redirect the user after the AJAX request is completed
                            window.location.href = redirect_url;
                        },
                        error: function (err) {
                            console.error('There was a problem:', err.responseText || err.statusText);
                            // Show an alert message to the user
                            showAlert('There was an error. A Student Service Representative will contact you to continue the process.');
                        }
                    });
                }
                resolve(); // Ensure the promise resolves if the modal is closed without clicking OK
            });
        });
    }
    


    $('#tour-code-form').on('submit', function (e) {
        e.preventDefault();

        let tourCode = $('#tour_code').val();

        if (tourCode) {
            // Construct the URL with the parameter
            let url = '/api/method/zoni_edu.controller.get_payment_link?code=' + encodeURIComponent(tourCode);

            // Call to Frappe API to verify the tour code using GET
            $.ajax({
                method: 'GET',
                url: url,
                dataType: 'json',
                success: function (data) {
                    if (data.message) {
                        $('#errorMessage').hide();
                        // Set the iframe's src to the Typeform link and open the modal
                        $('#typeformIframe').attr('src', data.message);
                        let myModal = new bootstrap.Modal($('#typeformModal')[0], {});
                        myModal.show();
                    } else {
                        $('#errorMessage').show();
                    }
                },
                error: function (err) {
                    console.error('There was a problem:', err.responseText || err.statusText);
                    $('#errorMessage').show();
                }
            });
        }
    });


    const videoElement = document.querySelector('.video-main');
    if (videoElement) {
        videoElement.addEventListener('canplaythrough', function () {
            // Start playing video only when it's ready to be played
            this.play();
        });
    }

    const videoElementKids = document.querySelector('.video-kids_awards');
    if (videoElementKids) {
        videoElementKids.addEventListener('canplaythrough', function () {
            // Start playing video only when it's ready to be played
            this.play();
        });
    }

});

var phoneInput = document.querySelector("#intl_phone_id");

// Hide the alert by default
$("#phone-error").hide();

function updatePhoneNumberValidity() {
    if (iti.isValidNumber()) {
        $("#phone-error").hide(); // Hide the alert
        $("button[type=submit]").prop("disabled", false); // Enable the submit button
    } else {
        (async function () {
            $("#phone-error").show().text(await translateText("Please enter a valid phone number for the selected country.")); // Show the alert with the error message
        })();
        $("button[type=submit]").prop("disabled", true); // Disable the submit button
    }
}

// When user types or focuses out of the phone input
$("#intl_phone_id").on("input blur", function () {
    updatePhoneNumberValidity();
});

// Listener for country change in the dropdown (replace '#country-selector' with your actual country dropdown ID or selector)
$('#country-selector').on('change', function() {
    var countryCode = $(this).val();
    iti.setCountry(countryCode);  // Set the country based on the dropdown
    updatePhoneNumberValidity();  // Manually trigger validation
});

// Check if phoneInput is available
if (phoneInput) {
    phoneInput.addEventListener('countrychange', function () {
        console.log('Country changed:', iti.getSelectedCountryData());
        console.log('Is valid?', iti.isValidNumber());
        updatePhoneNumberValidity();
    });
}



$("#email-error").hide();
$('#email').on('input', function () {
    if (!this.validity.patternMismatch) {
        $("#email-error").hide(); // Hide the alert
    }
});


document.addEventListener('DOMContentLoaded', function () {

    AOS.init();

    const languageSelector = document.querySelector('.language-selector');

    if(languageSelector) {
        const languageLinks = languageSelector.querySelectorAll('.dropdown-item');
        const dropdownButton = languageSelector.querySelector('#languageDropdownMenuButton');
        const langCodeText = languageSelector.querySelector('.lang-code-text');

        languageLinks.forEach(link => {
            link.addEventListener('click', function (event) {
                event.preventDefault();
                const language = this.getAttribute('data-language');
                const flagClass = this.querySelector('span').className;

                // Update the dropdown button flag
                dropdownButton.innerHTML = `<span class="${flagClass}"></span>`;

                // Update the language code text
                langCodeText.textContent = language;

                // Trigger Google Translate

                // triggerGoogleTranslate(language);
                Translator.translateContext(language)
            });
        });

        function triggerGoogleTranslate(languageCode) {
            var translateElement = document.getElementById('google_translate_element');
            translateElement.style.display = 'block'; // Show the widget

            // Find the Google Translate select element
            var select = translateElement.querySelector('.goog-te-combo');

            if (select) {
                select.value = languageCode; // Set the selected language
                // Trigger change event on the select element to translate the page
                var event = new Event('change');
                select.dispatchEvent(event);
                translateElement.style.display = 'none'; // Optionally hide it again
            } else {
                console.error('Google Translate select element not found');
            }
        }
    }


    function checkURL(){
        const urlObj = new URL(window.location.href);
        const pathSegments = urlObj.pathname.split('/');

        const filteredSegments = pathSegments.filter(segment => segment.length > 0);

        const secondLastSegment = filteredSegments[filteredSegments.length - 2];
        const thirdLastSegment = filteredSegments[filteredSegments.length - 3];

        if ((secondLastSegment === "tours" && thirdLastSegment !== "zoni") || thirdLastSegment === "job") {
            document.querySelector('.language-selector').classList.add('d-none');
        }
    }
    checkURL();

    ZoniPhoneNumbers.updatePhoneNumber();

    // Wait until the DOM has loaded before running the fetchTours function
    // Check the window's width
    if (window.innerWidth > 768) {
        // Only execute fetchTours if the screen width is more than 768
        TourDisplay.fetchTours();
        JobDisplay.fetchJobs();
    }
    else {
        TourDisplay.initializeFilters();
        JobDisplay.initializeFilters();
    }

    // Using debounce on filter changes to prevent excessive calls
    const debouncedFetchTours = Utils.debounce(TourDisplay.fetchTours, 250);
    const debouncedFetchJobs = Utils.debounce(JobDisplay.fetchJobs, 250);

    // Event listeners for Tours dropdowns
    let filterCategoryElem = $('#filter-category');
    if (filterCategoryElem.length) {
        filterCategoryElem.on('select2:select select2:unselect', function () {
            $(this).val($(this).val()).trigger('change'); // Force update value
            debouncedFetchTours();
        });
    }

    let filterDestinationElem = $('#filter-destination');
    if (filterDestinationElem.length) {
        filterDestinationElem.on('select2:select select2:unselect', function () {
            $(this).val($(this).val()).trigger('change'); // Force update value
            debouncedFetchTours();
        });
    }

    let filterDaysElem = $('#filter-days');
    if (filterDaysElem.length) {
        filterDaysElem.on('select2:select select2:unselect', function () {
            $(this).val($(this).val()).trigger('change'); // Force update value
            debouncedFetchTours();
        });
    }

    // Event listeners for Careers dropdowns
    let filterLocationElem = $('#filter-location');
    if (filterLocationElem.length) {
        filterLocationElem.on('select2:select select2:unselect', function () {
            $(this).val($(this).val()).trigger('change'); // Force update value
            debouncedFetchJobs();
        });
    }

    let filterTypeElem = $('#filter-type');
    if (filterTypeElem.length) {
        filterTypeElem.on('select2:select select2:unselect', function () {
            $(this).val($(this).val()).trigger('change'); // Force update value
            debouncedFetchJobs();
        });
    }

    let filterTitleElem = $('#filter-title');
    if (filterTitleElem.length) {
        filterTitleElem.on('select2:select select2:unselect', function () {
            $(this).val($(this).val()).trigger('change'); // Force update value
            debouncedFetchJobs();
        });
    }

    // $("#dropdown-menu").select2({ theme: 'bootstrap-5' });
    function searchFilter() {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("dropdown-menu");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }
    $("#myInput").on("keyup", function () {
        searchFilter();
    });

    Scroll.scrollToLeftById('#school-programs-left-arrow-id', '#school-programs-card-container');
    Scroll.scrollToRightById('#school-programs-right-arrow-id', '#school-programs-card-container');
    
    // Scroll.scrollToLeftById('#kids-testimonials-left-arrow-id', '#kids-testimonial-card-container');
    // Scroll.scrollToRightById('#kids-testimonials-right-arrow-id', '#kids-testimonial-card-container');

    // Scroll.scrollToLeftById('#kids-teachers-left-arrow-id', '#kids-teacher-cards-container');
    // Scroll.scrollToRightById('#kids-teachers-right-arrow-id', '#kids-teacher-cards-container');

    Scroll.scrollToLeftByIdKids('#kids-testimonials-left-arrow-id', '#kids-testimonial-card-container');
    Scroll.scrollToRightByIdKids('#kids-testimonials-right-arrow-id', '#kids-testimonial-card-container');

    Scroll.scrollToLeftByIdKids('#kids-teachers-left-arrow-id', '#kids-teacher-cards-container');
    Scroll.scrollToRightByIdKids('#kids-teachers-right-arrow-id', '#kids-teacher-cards-container');
    ResizeListenUpdates.transformYearsProgramSectionByResize();

    
// password.showPassword("showConfirmPassword","confirmPassword");
// password.showPassword("showPassword","password");

    $("#btnPrivateLessons").on('click', function (e) {
        e.preventDefault();
        $("#kids-group-carrousel-desktop").removeClass("d-lg-block d-xl-block").addClass("d-none");
        $("#kids-group-carrousel-mobile").removeClass("d-xs-block d-sm-block d-md-block d-lg-none").addClass("d-none");
        $("#kids-private-carrousel-desktop").removeClass("d-none").addClass("d-none d-lg-block d-xl-block");
        $("#kids-private-carrousel-mobile").removeClass("d-none").addClass("d-xs-block d-sm-block d-md-block d-lg-none");
        $("#btnPrivateLessons").addClass("active");
        $("#btnGroupClasses").removeClass("active");

        // $(".carousel-control-prev").show();
        // $(".carousel-control-next").show();
    })
    $("#btnGroupClasses").on('click', function (e) {
        $("#kids-private-carrousel-desktop").removeClass("d-lg-block d-xl-block").addClass("d-none");
        $("#kids-private-carrousel-mobile").removeClass("d-xs-block d-sm-block d-md-block d-lg-none").addClass("d-none");
        $("#kids-group-carrousel-desktop").removeClass("d-none").addClass("d-none d-lg-block d-xl-block");
        $("#kids-group-carrousel-mobile").removeClass("d-none").addClass("d-xs-block d-sm-block d-md-block d-lg-none");
        $("#btnGroupClasses").addClass("active");
        $("#btnPrivateLessons").removeClass("active");

        // $(".carousel-control-prev").hide();
        // $(".carousel-control-next").hide();
    })

    var paymentCodeLink = "{{ context.payment_code_link }}";

    $('#openBookingTourModalBtn').click(function(event) {
        event.preventDefault(); // Prevent the default action
        var paymentCodeLink = $(this).data('payment-code-link'); // Get the data attribute
        $('#typeformIframe').attr('src', paymentCodeLink); // Set the iframe src to the payment code link
        var modal = new bootstrap.Modal(document.getElementById('typeformModalTourBooking')); // Initialize the modal
        modal.show(); // Show the modal
    });


    EnrollNow.initializeEnrollNow();
    Translator.updateLanguageDropdown();


    // Initialize Zoni Kids Free trial buttons
    document.querySelectorAll('.free-trial-btn').forEach(button => {
        button.addEventListener('click', async function () {
            // Close any other open modal
            var openModals = document.querySelectorAll('.modal.show');
            openModals.forEach(modal => {
                var bootstrapModal = bootstrap.Modal.getInstance(modal);
                bootstrapModal.hide();
            });

            var modalElement = document.getElementById('zoniModal');
            VisitorTracking.setIsKidsFreeTrial(true);
            VisitorTracking.customizeModalForKidsFreeTrial();
            var bootstrapModal = new bootstrap.Modal(modalElement);
            bootstrapModal.show();
        });
    });

    //Show the custom toast message
    var toastEl = document.getElementById('customToast');
    if (toastEl) {
        var toast = new bootstrap.Toast(toastEl, { delay: 300000 });
        toast.show();
    }

    StoreProducts.loadProducts();
});

window.addEventListener('resize', Utils.debounce(TourDisplay.fetchTours, 250));
window.addEventListener('resize', Utils.debounce(JobDisplay.fetchJobs, 250));
